@import '@nerdwallet/base-styles/styles.scss';
@import '../../../../styles/react-media-queries.scss';
@import '../../../../styles/front-page-header-variables.scss';

.tabPanel {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-right: 1px solid var(--green-light, #40ad87);
  border-left: 1px solid var(--green-light, #40ad87);
  border-bottom: 1px solid var(--green-light, #40ad87);
  @media (max-width: 1279px) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
    border-left: none;
    border-bottom: none;
    box-shadow: none;
  }
}

.tabsContainer {
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow:
    0px 8px 0px 0px rgba(0, 130, 84, 1),
    0px 16px 0px 0px rgba(64, 173, 135, 1);

  @media (max-width: 1279px) {
    border-radius: 0px;
    box-shadow: none;
  }

  .tabsList {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 80px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    // Tabs are scrollable on mobile
    @media (max-width: 1279px) {
      height: auto;
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  }

  .tabWrapper {
    width: 100%;
    margin-left: -10px;

    &:nth-child(1) {
      margin-left: 0 !important;
      button {
        padding-right: 20px !important;
      }
    }
  }

  .tab {
    // Base styles
    background: #95dcc3;
    width: 100%;
    display: flex;
    padding: 16px 16px 16px 24px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    color: $green-dark;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid var(--green-light, #40ad87);
    border-radius: 16px 24px 0px 0px;
    box-shadow: 0px 0px 3px 0px rgba(44, 85, 71, 0.3);
    cursor: pointer;
    transition: background 0.3s;
    text-wrap: nowrap;

    // Mobile styles
    @media (max-width: 1279px) {
      padding: 16px;
    }

    &:hover {
      padding-bottom: 24px !important;
      @media (max-width: 1279px) {
        padding-bottom: 16px !important;
      }
    }

    &.active {
      padding: 16px 24px 16px 24px;
      border-top: 1px solid var(--green-light, #40ad87);
      border-right: 1px solid var(--green-light, #40ad87);
      border-left: 1px solid var(--green-light, #40ad87);
      border-bottom: none;
      @media (max-width: 1279px) {
        padding: 16px;
      }
    }

    // // Wrap tabs texts when we shrink the screen between 901px and 1280px.
    // @media (min-width: 901px) and (max-width: 1280px) {
    //   text-wrap: wrap;
    //   @include padding-all-2;
    //   height: 54px;
    //   min-width: 80px;
    //   &:hover {
    //     height: 62px;
    //     padding-bottom: 8px;
    //   }
    // }
  }
}
